/* New Feedback Modal Styles */
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal_container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 520px;
  max-width: 95vw;
  max-height: 95vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e6e6e6;
}

.modal_title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.close_button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  padding: 4px;
}

.close_button:hover {
  color: #333;
}

.modal_content {
  padding: 20px;
  flex: 1;
}

.modal_subtitle {
  font-size: 15px;
  color: #555;
  margin: 0 0 20px 0;
}

.upload_area {
  margin-bottom: 24px;
}

.upload_zone {
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 40px 20px;
  text-align: center;
  position: relative;
}

.file_input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.upload_label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.upload_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #666;
  margin-bottom: 8px;
}

.upload_text {
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.upload_limit {
  font-size: 13px;
  color: #777;
}

.tags_section {
  margin-bottom: 24px;
}

.tags_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.tags_label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.tags_counter {
  font-size: 13px;
  color: #777;
}

.tags_input_container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 12px;
}

.other_input_container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px 12px;
}

.buttons {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;
}

.button_icon {
  font-size: 25px;
}

.button_title {
  font-size: 15px;
  font-weight: 600;
}

.button_description {
  font-size: 13px;
  color: #777;
}

.button_container:hover {
  background-color: #fff;
  border: 2px solid #0055d4;
}

.button_container_selected {
  background-color: #fff;
  border: 2px solid #0055d4 !important;
}

.button_container {
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  gap: 8px;
  padding: 8px 12px;
  margin-bottom: 12px;
  width: calc((100% - 16px) / 2);
}

.tags_input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 8px 0;
}

.tags_input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 8px 0;
}

.tags_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  border-radius: 16px;
  padding: 4px 10px;
  font-size: 13px;
  color: #333;
}

.tag_remove {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #666;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.predefined_tags {
  display: flex;
  gap: 10px;
}

.predefined_tag {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 13px;
  color: #444;
  cursor: pointer;
}

.predefined_tag:hover {
  background-color: #e9e9e9;
}

.modal_footer {
  padding: 16px 20px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save_draft_button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.save_draft_button:hover {
  background-color: #f5f5f5;
}

.footer_right {
  display: flex;
  gap: 12px;
}

.schedule_button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.schedule_button:hover {
  background-color: #f5f5f5;
}

.share_button {
  background-color: #0066ff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.share_button:hover {
  background-color: #0055d4;
}

.input_container_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
