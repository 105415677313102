.wrapper {
  border-bottom: 2px solid var(--charcoalLight);
  border-top: none;
  background-color: var(--charcoal);
  border-radius: 0px 0px 4px 4px;
}

.open_wrapper {
  border-bottom: 2px solid var(--charcoal);
  background-color: var(--charcoalLight);
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.drop_down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  cursor: pointer;
  font-size: 13px;
  color: #fff;
}

.name {
  font-weight: 600;
  font-size: 13px;
}

.arrow_down {
  color: var(--skyBlue);
}

.header_type {
  border: 1px solid #fff;
  padding: 4px 16px;
  border-radius: 8px;
  width: 90px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 45px;
  height: 20px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 1px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}
