html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  user-select: none;
}

:root {
  --charcoal: #303030;
  --charcoalLight: #3c3c3c;
  --skyBlue: #38b2d9;
  --skyBlueLight: #52c8ed;
  --greyDarkest: #6d6d6d;
  --smoke: #f2f2f2;
  --smokeDarker: #e2e2e2;
  --smokeDarkest: #d6d6d6;
  --blue: #0960e4;
  --blueLight: #2e81ff;
  --lime: #97b912;
  --limeDarker: #83a309;
  --teal: #00a19b;
  --gold: #deaf00;
  --orange: #ff6624;
  --magenta: #d84884;
  --theme-root-bg: #0d0d0d;
  --theme-page-bg: white;
  --theme-page-bg-2: #000000;
  --theme-page-text: black;
  --theme-page-text-secondary: #d8d9db;
  --theme-page-text-input: #e5e5e5;
  --theme-active-tab-bg: #2d2d2e;
  --theme-badge-bg: #4e4d4d;
  --theme-gradiant-start: #35374000;
  --theme-avtar-bg: #5d4037;
  --theme-avtar-workstream: #19c37d;
  --theme-avtar-workstream-border: #5ed5a4;
  --theme-avtar-user: #5d4037;
  --theme-avtar-user-border: #8a756f;
  --theme-chat-box-bg: white;
  --theme-error-banner-bg: #ef44441a;
  --theme-chat-error-text: #ececf1;
  --theme-error-banner-border: #ef4444;
  --theme-icon-color: #dfe1e4;
  --theme-popup-bg: #343541e6;
  --theme-popup-inner-bg: white;
  --theme-like-icon: #1a7f64;
  --theme-like-icon-bg: #d2f4d3;
  --theme-dislike-icon: #dc2626;
  --theme-dislike-icon-bg: #fee2e2;
  --theme-popup-field-bg: white;
  --theme-popup-submit-bg: white;
  --theme-popup-field-border: #565869;
  --theme-error-text: #f81e1e;
  --theme-splash-screen-bg: #222223;
  --theme-splash-screen-chat-bg: white;
  --theme-accept-button: #00bfa6;
  --theme-accept-button-text: white;
  --theme-accept-button-disabled: #8fcdc4;
  --theme-code-block-header: steelblue;
  --theme-code-block-hover-bg: cornflowerblue;
  --theme-textarea-bg: #3a3b3c;
  --theme-input-field-bg: transparent;
  --theme-input-field-border: #40414f;
  --theme-input-title: #8e92b8;
  --theme-selected-navigation-item-bg: #3e3e42;
  --theme-navigation-item-hover-bg: #5b5b6e;
  --theme-navaigation-item-loader-bg: #505054;
  --theme-navigation-item-svg-hover-bg: white;
  --theme-delete-btn-bg: #ae5e5e;
  --theme-delete-btn-text: white;
  --theme-selected-chat-bg: #414a50;
  --theme-selected-chat-avtar-bg: #80909e;
  --theme-btn-hover-bg: #393a47;
  --theme-banner-bg: #f7f7f8;
  --theme-banner-text: white;
  --theme-save-btn-bg: white;
  --theme-sql-header-color: aliceblue;
  --theme-download-btn-color: white;
  --theme-download-btn-bg: white;
  --theme-table-header-bg: #242526;
  --theme-table-hader-text: white;
  --theme-table-column-bg: #343541e6;
  --theme-border-color: #555561;
  --theme-pin-color: black;
  --theme-white: white;
  --theme-gray: #5b5b5c;
  --theme-send-icon: #e5e5e5;
  --theme-send-icon-splash: #e5e5e5;
  --theme-dummy-meessage-hove: #f7f7f8;
  --theme-nav-left-header-bg: #f7f7f8;
  --theme-sidebar-hover: #5b5b5c;
  --theme-sidebar-list_hover: #2b2c2e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
