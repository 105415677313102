.wrapper {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 2px solid var(--blue);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  padding: 3px 6px;
  justify-content: space-evenly;
  cursor: pointer;
  font-size: 10px;
}

.baseCardFilter {}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: var(--charcoal);
  margin: 0 6px;
}


input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: blue;
  width: 12px;
  height: 12px;
  border: 2px solid blue;
  border-radius: 3px;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: none;
}