.wrapper {
  border: 2px solid var(--smokeDarker);
  border-top: none;
  background-color: var(--smoke);
  font-size: 12px;
}

.open_wrapper {
  border: 2px solid var(--smokeDarker);
  border-top: none;
  background-color: #fff;
}

.filterRegion {
  padding: 0 16px 16px;
}

.dropDownArea {
  height: 151px;
  overflow-y: auto;
}

.open_region {
  padding: 16px;
  display: flex;
  width: 100%;
  align-items: center;
}

.input {
  outline: none;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  color: var(--charcoal);
  padding: 2px 16px;
  text-align: center;
  width: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.value {
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  padding: 2px 16px;
}

.slider_wrapper {
  padding: 4px;
  position: relative;
  width: 100%;
  margin-right: 16px;
  width: 207px;
}

.min {
  position: absolute;
  left: 12px;
  top: 12px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
}

.max {
  position: absolute;
  right: 1px;
  top: 11px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
}
