.button_section {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: var(--smoke);
    border: 2px solid var(--smokeDarker);
    border-radius: 0px 0px 8px 8px;
}

.button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 6px 0;
    background: var(--skyBlueLight);
    border: 2px solid var(--skyBlue);
    border-radius: 4px;
    min-width: 160px;
    cursor: pointer;
}

.button_right_icon {
   color: var(--charcoal);
   font-weight: 600;
   font-size: 20px;
   display: flex;
}

.button_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--charcoal);
}