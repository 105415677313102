.open_region {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.input_region {
  width: 100%;
  gap: 16px;
}

.input_wrapper {
  display: flex;
  /* flex-direction: column; */
  position: relative;
}

.option {
  padding: 4px;
  border-radius: 4px 0px 0px 4px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
}

.input {
  padding: 5px 10px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  width: 100%;
  color: var(--charcoal);
  outline: none;
  border: 2px solid #0a1b34;
  border-radius: 8px;
}

.regions_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  max-width: 310px;
  overflow: overlay;
  gap: 4px;
  margin-bottom: 10px;
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 6px 12px;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  border-radius: 4px;
  cursor: pointer;
  /* position: absolute; */
  right: 0;
  bottom: 0;
  margin-left: 5px;
  transition: all linear 1s;
}

.regions_item {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 2px solid var(--blue);
  border-radius: 16px;
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
}
