.root {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.card {
  background: var(--charcoal);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 32px;
  font-size: 10px;
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: 650px;
  min-height: 600px;
  width: 95%;
  height: 98%;
}

.header {
  display: flex;
  justify-content: center;
  color: #fff;
  height: 30px;
  margin: 0;
}

.panel {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow: auto;
}

.panel_select {
  display: flex;
  align-items: center;
  gap: 24px;
}

.panel_header_selected {
  font-size: 14px;
  color: var(--skyBlueLight);
  font-weight: 900;
  border-bottom: 2px solid var(--skyBlueLight);
  cursor: pointer;
}

.panel_header {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  font-weight: 900;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 42px;
  margin-top: 16px;
}

.button {
  padding: 10px 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--skyBlueLight);
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--skyBlueLight);
}

.button_outline {
  padding: 10px 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  color: var(--skyBlue);
  border: 2px solid var(--skyBlueLight);
}

.edge_option_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* option-icons */
.family_icon {
  color: var(--blue);
  margin-right: 6px;
}

.professional_icon {
  color: var(--teal);
  margin-right: 6px;
}

.organization_icon {
  color: var(--lime);
  margin-right: 6px;
}

.charitable_icon {
  color: var(--gold);
  margin-right: 6px;
}

.education_icon {
  color: var(--orange);
  margin-right: 6px;
}

.context {
  padding: 0 20px;
  margin: 0 auto 20px;
  justify-content: center;
}

.panel::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 8px;
}

.panel::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--skyBlue);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.close {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}
