.filter_by_wrapper {
  border-bottom: 2px solid var(--charcoalLight);
  border-top: none;
  background-color: var(--charcoal);
  font-size: 12px;
}

.filter_by_open_wrapper {
  border-bottom: 2px solid var(--charcoal);
  /* border-top: none; */
  font-size: 12px;
  background: var(--charcoalLight);
}
