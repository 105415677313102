.drop_down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  cursor: pointer;
  font-size: 13px;
  color: #fff;
}

.name {
  font-weight: 600;
  font-size: 13px;
}

.arrow_down {
  color: var(--skyBlue);
}

.header_type {
  border: 1px solid #fff;
  padding: 4px 16px;
  border-radius: 8px;
  width: 90px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
