.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.input_label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #6D6D6D;
}

.input {
    color: var(--charcoal);
    background-color: #F2F2F2;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    outline: none;
    padding: 2px 0 2px 8px;
    height: 28px;
}

.split_input {
    color: var(--charcoal);
    background-color: #F2F2F2;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    outline: none;
    padding: 2px 8px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.split_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--charcoal);
}

.split_amount {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;    
    color: var(--charcoal);
}

.icon_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}