.wrapper {
  border-bottom: 2px solid var(--charcoalLight);
  border-top: none;
  background-color: var(--charcoal);
  border-radius: 0px 0px 4px 4px;
}

.open_wrapper {
  border-bottom: 2px solid var(--charcoal);
  background-color: var(--charcoalLight);
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.open_region {
  padding: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 8px 16px;
}

.first_degree {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px 0px 0px 4px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  text-align: center;
  width: 33%;
  margin: 0;
  border-right: none;
  cursor: pointer;
}

.first_degree:hover {
  border: 2px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
}

.first_degree_selected {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--blue);
  border-radius: 4px 0px 0px 4px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  background-color: var(--blue);
  text-align: center;
  width: 33%;
  margin: 0;
  border-right: none;
  cursor: pointer;
}

.last_degree {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--smokeDarker);
  border-radius: 0px 4px 4px 0px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  text-align: center;
  margin: 0;
  border-left: none;
  width: 33%;
  cursor: pointer;
}
.last_degree_selected {
  background: #ffffff;
  padding: 4px;
  border-radius: 0px 4px 4px 0px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin: 0;
  border-left: none;
  width: 33%;
  cursor: pointer;
  border: 2px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
}
.last_degree:hover {
  border: 2px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
}

.degree {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--smokeDarker);
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  text-align: center;
  width: 33%;
  margin: 0;
  cursor: pointer;
}
.degree_selected {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--blue);
  font-weight: 600;
  font-size: 16px;
  background-color: var(--blue);
  color: #fff;
  text-align: center;
  width: 33%;
  margin: 0;
  cursor: pointer;
}
.degree:hover {
  border: 2px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
}
