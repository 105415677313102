.filter_cards_wrapper {
  display: flex;
  flex-direction: column;
  min-width: 500px;
}

.visible_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 50%;
  padding: 0 6px;
  cursor: pointer;
}

.filters {
  display: flex;
  margin-bottom: 12px;
  gap: 8px;
  justify-content: center;
}

@media (max-width: 1254px) {
  .filters {
    display: flex;
    margin-bottom: 12px;
    gap: 2px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* option-icons */
.family_icon {
  color: var(--blue);
  margin-right: 6px;
}

.professional_icon {
  color: var(--teal);
  margin-right: 6px;
}

.organization_icon {
  color: var(--lime);
  margin-right: 6px;
}

.charitable_icon {
  color: var(--gold);
  margin-right: 6px;
}

.education_icon {
  color: var(--orange);
  margin-right: 6px;
}
