.input_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border: 2px solid #0a1b34;
  border-radius: 8px;
  padding: 6px 5px 6px 12px;
  color: var(--charcoal);
  gap: 8px;
  background-color: #fff;
}

.remove_target {
  font-size: 20px;
  color: var(--skyBlue);
  cursor: pointer;
}

.wrapper {
  padding: 0 4px;
}

.vertical_line {
  height: 16px;
  border-left: 2px dashed #cacaca;
  margin-left: 16px;
}

.search_input {
  background-color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  color: var(--charcoal);
  outline: none;
  border: none;
  text-overflow: ellipsis;
  border-radius: 8px;
  font-size: 14px;
}

.icon {
  right: 12px;
  font-size: 25px;
  color: var(--skyBlue);
  cursor: pointer;
}

.node_count {
  right: 36px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  padding: 1px 8px;
  background: var(--skyBlue);
}

.items_wrapper {
  display: flex;
  flex-direction: column;
  height: 184px;
  overflow-y: overlay;
  background: #fff;
  border: 2px solid #e2e2e2;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
}

.search_item {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search_item:hover {
  background-color: var(--smoke);
}

.search_item_icon {
  margin-right: 8px;
  background-color: var(--skyBlue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.search_icon {
  font-size: 16px;
  margin-right: 8px;
  color: var(--skyBlue);
}

.search_item_value {
  font-weight: 400;
  font-size: 16px;
  color: #13294b;
}

.dropdownCard {
  background: #ffffff;
  border: 2px solid black;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px 16px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  position: relative;
}

.dropDownItem {
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  color: var(--charcoal);
}

.dropDownItem:hover {
  background-color: rgb(219, 219, 219);
}

.close_icon {
  position: absolute;
  right: 8px;
  font-size: 22px;
  cursor: pointer;
}

.bottomBorder {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropDownItemSelected {
  background-color: var(--skyBlue);
}

.dropDownItemSelected:hover {
  background-color: var(--skyBlue);
}
