.engagement_icon {
    width: 20px;
    height: 20px;
    background: var(--magenta);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-right: 6px;
}
  
.engagement_icon_img {
    width: 15px;
}