.wrapper {
  border-top: none;
  border-bottom: 2px solid var(--charcoal);
  background-color: var(--charcoalLight);
}

.filterRegion {
  padding: 0 8px 16px;
  position: relative;
  color: var(--charcoal);
}

.dropDownArea {
  height: 151px;
  overflow-y: auto;
}

.input_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.search_input {
  padding: 6px 16px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  color: var(--charcoal);
  outline: none;
  border: 2px solid #0a1b34;
  height: 40px;
  border-radius: 8px;
}

.icon {
  position: absolute;
  right: 12px;
  font-size: 20px;
  color: var(--skyBlue);
}

.items_wrapper {
  display: flex;
  flex-direction: column;
  height: 184px;
  width: 273px;
  overflow-y: overlay;
  background: #fff;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  margin-left: 2px;
}

.search_item {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search_item:hover {
  background-color: var(--smoke);
}

.search_item_icon {
  margin-right: 8px;
  background-color: var(--skyBlue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.search_icon {
  font-size: 12px;
  margin-right: 8px;
  color: var(--skyBlue);
}

.search_item_value {
  font-weight: 400;
  font-size: 12px;
  color: #13294b;
}

.search_wrapper {
  width: 100%;
  height: 50px;
  background: var(--charcoalLight);
  border: 2px solid var(--charcoal);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
  color: #fff;
  cursor: pointer;
}

.search_card_wrapper {
  height: calc(100% - 32px);
  background-color: #fff;
  width: 100%;
  border: 2px solid #e2e2e2;
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 16px;
}

.search_type {
  border: 1px solid #fff;
  padding: 4px 16px;
  border-radius: 8px;
  width: 80px;
  display: flex;
  justify-content: center;
}

.regions_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 8px 0;
  overflow: overlay;
  gap: 8px;
  margin-bottom: 10px;
}

.regions_item {
  display: flex;
  align-items: center;
  background: var(--charcoalLight);
  border: 2px solid var(--skyBlue);
  border-radius: 16px;
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;
  font-size: 12px;
  height: 40px;
  position: relative;
  color: #fff;
}

.clear_icon {
  position: absolute;
  top: -6px;
  right: -7px;
  width: 17px;
  height: 17px;
  color: #fff;
  padding: 2px;
  border-radius: 50%;
  background: #ff0000;
  font-size: 17px;
}
