.wrapper {
  border-radius: 8px;
  width: 100%;
  background-color: #fff;
}

.content {
  padding: 16px;
  border: 2px solid var(--smokeDarker);
  border-top: none;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 0px 0px 8px 8px;
}

.right_arrow {
  cursor: pointer;
}