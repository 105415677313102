.card {
  min-width: 400px;
  height: calc(100vh - 64px);
  overflow-y: overlay;
}

.top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--charcoal);
  border: 2px solid var(--charcoal);
  border-radius: 8px 8px 0px 0px;
  padding: 8px 16px;
}

.back_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow_back {
  color: var(--lime);
}

.back_text {
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.three_dots {
  color: var(--lime);
}

.header_background {
  background-color: var(--charcoalLight);
  background-color: var(--charcoalLight);
  border: 2px solid var(--charcoal);
  padding: 16px;
  height: 120px;
  box-sizing: border-box;
  padding-bottom: 0px;
}

.person_header {
  display: flex;
}
.left_person_header_section {
  width: 70%;
}

.right_person_header_section {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card_header_wrapper {
  width: 100%;
  height: 32px;
  line-height: 40px;
  background: var(--charcoalLight);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.card_header_value {
  color: #fff;
  margin: 0;
  line-height: 24px;
}

.cardHeaderIcon {
  color: var(--lime);
  font-size: 25px;
  margin-right: 8px;
}

.name {
  font-weight: 700;
  font-size: 32px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organization_tags_wrapper {
  height: 32px;
  background-color: var(--lime);
  border: 2px solid var(--limeDarker);
  border-top: none;
  overflow: overlay;
  padding: 0 10px;
  overflow: overlay;
  padding: 0 16px;
}

/* --- Content --- */
.content_wrapper {
  height: calc(100% - 267px);
  overflow-y: overlay;
  background: #fff;
}

.content {
  padding: 16px;
  border: 2px solid var(--smokeDarker);
  border-top: none;
}

.two_section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 16px;
}

.left_two_section {
  width: 50%;
  padding-right: 4px;
}

.right_two_section {
  width: 50%;
  padding-left: 4px;
}

.age_country_section {
  display: flex;
  margin-top: 16px;
  align-items: center;
}

.age_section {
  width: 44px;
  padding-right: 4px;
}

.country_section {
  width: calc(100% - 44px);
  padding-left: 4px;
}

.input_wrapper {
  margin-top: 16px;
  width: 100%;
}

/* --- More Section --- */
.more_section {
  padding: 32px 16px;
  background-color: var(--smoke);
  border: 2px solid var(--smokeDarker);
  border-top: none;
  border-bottom: none;
}

.connections_wrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.connections_title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--charcoal);
  border-top: 4px solid var(--lime);
  margin-bottom: 16px;
}

.top_relationships_wrapper {
  margin-top: 16px;
}

.tiered_wrapper {
  margin-top: 16px;
}
