.card {
    max-width: 360px;
    box-sizing: border-box;
}

.content {
    background-color: #fff;
    width: 100%;
    border: 2px solid #E2E2E2;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    padding: 16px;
}

.content_header {
    display: flex;
    align-items: center;
    width: 100%;
}

.left_section {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.name {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.name_desc {
    font-weight: 700;
    margin: 0 3px 0 0;
    font-size: 24px;
    line-height: 32px;
    color: var(--charcoal);
}

.name_icon {
    font-size: 25px;
    color: #38B2D9;
    margin-top: 3px;
}

.person_details_wrapper {
    display: flex;
    align-items: center;
}

.person_details_title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--greyDarkest);
    margin-right: 4px;
}

.person_details_value {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--charcoal);
    width: 208px;
    text-overflow: ellipsis;
}

.types {
    display: flex;
    background: var(--smoke);
    border-radius: 4px;
    height: 28px;
    width: 100%;
    margin-top: 16px;
    padding: 0 16px;
}

.right_section {
    width: 30%;
    display: flex;
    justify-content: center;
}
