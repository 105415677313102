.chart_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.print_button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  font-size: 19px;
  border: 2px solid var(--charcoal);
  width: 35px;
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.print_button:hover {
  background-color: var(--charcoal);
  color: #fff;
}

.no_error {
  display: flex;
  position: absolute;
  z-index: 190;
  left: 50%;
  bottom: 50%;
  font-weight: 600;
  font-style: italic;
}
