.wrapper {
  width: 100%;
  height: 32px;
  background: var(--charcoalLight);
  border: 2px solid var(--charcoal);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
}

.text {
  display: flex;
  align-items: center;
  height: 100%;
}

.value {
  color: #fff;
  margin: 0;
}

.cardHeaderIcon {
  color: var(--skyBlue);
  font-size: 25px;
  margin-right: 8px;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
}

.three_dots {
  cursor: pointer;
  font-size: 20px;
}

.distance_wrapper {
  display: flex;
  background: var(--skyBlue);
  border: 1px solid var(--skyBlue);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 2px;
  margin-right: 5px;
}

.distance_wrapper:hover {
  background-color: #fff;
  border-color: #fff;
  color: var(--skyBlue);
}
.hide {
  display: flex;
  background: #d9534f;
  border: 1px solid var(--charcoal);
  align-items: center;
  margin-left: auto;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  padding: 3px 2px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
}
