.item_wrapper {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.name {
  display: flex;
  align-items: center;
}

.value {
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
}

.relationship {
  font-weight: 400;
  font-size: 14px;
  color: var(--greyDarkest);
}

.right_arrow {
  color: var(--skyBlue);
  margin-left: 8px;
  cursor: pointer;
}

.loading_placeholder {
  min-width: 250px;
  height: 16px;
  background-color: #eee;
  border-radius: 4px;
}
