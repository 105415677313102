.slider {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px 2px;
}

.slider_track_container {
    display: flex;
    width: 100%;
}

.slider_track {
    height: 5px;
    width: 100%;
    border-radius: 5px;
    align-self: center;
}

.slider_thumb_container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--skyBlueLight);
    border: 2px solid var(--skyBlue);
}

.icon {
    position: absolute;
    font-size: 20px;
    right: 7px;
}

.icon_right {
    position: absolute;
    font-size: 20px;
    left: 7px;
}

.slider_thumb {
    height: 16px;
    width: 5px;
    background: transparent;
    outline: none !important;
}