.open_region {
  padding: 2px 8px 16px;
  display: flex;
  flex-direction: column;
}

.input_region {
  width: 100%;
  gap: 16px;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
}

.option {
  padding: 4px;
  border-radius: 4px 0px 0px 4px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
}

.input {
  padding: 3px 8px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  width: 100%;
  color: var(--charcoal);
  outline: none;
  border: 2px solid #0a1b34;
  border-radius: 8px;
}
