.wrapper {
  border: 2px solid var(--smokeDarker);
  border-top: none;
  background-color: var(--smoke);
}

.open_wrapper {
  border: 2px solid var(--smokeDarker);
  border-top: none;
  background-color: #fff;
}

.filterRegion {
  padding: 0 16px 16px;
}

.dropDownArea {
  height: 151px;
  overflow-y: auto;
}

.item_wrapper {
  border: 2px solid var(--smokeDarker);
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  cursor: pointer;
}

.item_wrapper:hover {
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
}

.item {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
}

.filter_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 8px;
  row-gap: 10px;
  padding: 16px;
}

.item_wrapper_selected {
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  cursor: pointer;
}
