.input_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.wrapper {
  position: relative;
}
.clearAll {
  border: 1px solid #fff;
  padding: 4px;
  border-radius: 8px;
  width: 61px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.search_input {
  padding: 6px 16px;
  background-color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  color: var(--charcoal);
  outline: none;
  border: 2px solid #0a1b34;
  border-radius: 8px;
}

.icon {
  position: absolute;
  right: 12px;
  font-size: 20px;
  color: var(--skyBlue);
}

.items_wrapper {
  display: flex;
  flex-direction: column;
  height: 184px;
  overflow-y: overlay;
  background: #fff;
  /* border: 2px solid #e2e2e2; */
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: 98px;
  left: 0;
  width: 95%;
  margin: 0 8px;
}

.search_item {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search_item:hover {
  background-color: var(--smoke);
}

.search_item_icon {
  margin-right: 8px;
  background-color: var(--skyBlue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.search_icon {
  font-size: 16px;
  margin-right: 8px;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  color: var(--skyBlue);
}

.search_item_value {
  font-weight: 400;
  font-size: 16px;
  color: #13294b;
}

.search_wrapper {
  width: 100%;
  height: 50px;
  background: var(--charcoalLight);
  border: 2px solid var(--charcoal);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
  color: #fff;
  cursor: pointer;
  position: relative;
  font-size: 12px;
}

.search_card_wrapper {
  height: calc(100% - 32px);
  width: 100%;
  border-top: none;
  padding: 6px;
}

.search_type {
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.family_icon {
  color: var(--blue);
  margin-right: 6px;
}

.professional_icon {
  color: var(--teal);
  margin-right: 6px;
}

.organization_icon {
  color: var(--lime);
  margin-right: 6px;
}

.charitable_icon {
  color: var(--gold);
  margin-right: 6px;
}

.education_icon {
  color: var(--orange);
  margin-right: 6px;
}

.searchTypeHeaderWrapper {
  display: flex;
  position: relative;
  align-items: center;
  gap: 4px;
  border: 1px solid #fff;
  padding: 4px 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.arrow_down {
  color: var(--skyBlue);
}

.dropDownCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  gap: 3px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  border-top: none;
  top: 38px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  right: 0;
  z-index: 10;
  color: var(--charcoal);
  width: 113px;
}

.options {
  width: 100%;
  padding: 5px 16px;
}

.options:hover {
  color: #fff;
  background-color: var(--skyBlue);
  font-weight: 700;
}

.is_selected {
  width: 100%;
  text-align: center;
  padding: 5px 0;
  color: #fff;
  background-color: var(--skyBlue);
  font-weight: 700;
}

.textarea_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.textarea_error {
  font-size: 12px;
}

.textarea {
  font-size: 14px;
}

.button {
  text-decoration: none;
  display: flex;
  height: 40px;
  padding: 1rem 2rem;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: var(--skyBlue);
  color: #fff;
  width: 100%;
  font-weight: 700;
}

.search_header {
  margin-right: 8px;
  white-space: nowrap;
  font-weight: 700;
}

.items_error {
  color: var(--charcoal);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
