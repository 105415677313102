.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.023em;
  color: #dfe1e4;
  margin-bottom: 4px;
}

.icon {
  position: absolute;
  right: 15px;
  top: 27px;
  color: #dfe1e4;
}

.datepicker {
  padding: 10px 0;
  height: 16px;
  background: #3a3b3c;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  border: 1px solid #3a3b3c;
  min-width: 150px;
  text-indent: 10px;
  color: #dfe1e4;
}

.datepicker:disabled {
  opacity: 0.5;
}

.error_text {
  margin-top: 4px;
  font-size: 12px;
  color: #f81e1e;
}
