.login-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #13294b;
  background-image: linear-gradient(160deg, #929fb5 0%, #3a4461 98%);
  position: relative;
}

.login-logo-wrapper {
  position: absolute;
  display: none;
  height: 81px;
  width: 100%;
  background: transparent;
  /* @media (max-width:  $app-breakpoint-m) {
        background: #0266d4;
        display: block;
    } */
}

.login-logo {
  width: 162px;
  height: 35px;
  position: absolute;
  top: 23px;
  left: 23px;
}

.base-button {
  text-decoration: none;
  display: flex;
  height: 56px;
  padding: 1rem 2rem;
  font-size: 14px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
}

.login-right-section {
  width: 60%;
  height: 100%;
  position: relative;
  /* @media (max-width: 1209px) {
      width: 50%;
    }
  
    @media (max-width: $app-breakpoint-m) {
      display: none;
    } */
}

.login-img {
  width: 80%;
  margin-left: 10%;
}

.login-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-left-section {
  width: 40%;
  height: 100%;
  background: #fff;

  /* @media (max-width: 1209px) {
      width: 50%;
    }
    @media (max-width: $app-breakpoint-m) {
      width: 100%;
    } */
}

.login-login-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 97%;
  margin: 0 90px;
}

.login-logo-icon {
  width: 35px;
  height: 35px;
}

.login-header {
  font-weight: 900;
  letter-spacing: 0.02em;
  margin: 40px 0 0 0;
  padding: 0;
}

.login-subheader {
  font-weight: normal;
  font-size: 0.9rem;
  letter-spacing: 0.02em;
  color: #606060;
  padding: 0;
  margin: 12px 0 0 0;
}

.login-single-sign-on-wrapper {
  margin: 30px 0 0;
  width: 100%;
}

.login-button-single-sign-on {
  min-width: 200px;
  font-weight: 600;
  background-color: #13294b; /* University of Illinois blue */
  border: none;
  color: #ffffff;
  margin: 0;
}

.login-button-single-sign-on:hover {
  background-color: #1d3c6e; /* Slightly lighter blue */
}

.login-divider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 24px 0 0;
  width: 100%;
}

.login-divider-left {
  margin-right: 10px;
  height: 1px;
  width: 100%;
  background-color: #c3c3c3;
}

.login-divider-right {
  margin-left: 10px;
  height: 1px;
  width: 100%;
  background-color: #c3c3c3;
}

.login-divider-text {
  color: #a6a6a6;
  font-size: 14px;
  min-width: 110px;
  text-align: center;
}

.login-form {
  padding: 0;
  margin-top: 24px;
}

.login-label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
}

.login-email-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-email-input-wrapper {
  display: flex;
  position: relative;
  align-items: center;

  @media (max-width: $app-breakpoint-s) {
    justify-content: center;
  }
}

.login-password-wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-password-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: $app-breakpoint-s) {
    justify-content: center;
  }
}

.login-input-error {
  color: #b80024;
  margin: 5px 0 0 10px;
  font-size: 12px;
}

.login-input {
  color: #333;
  font-size: 1rem;
  padding: 1.3rem 1rem;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  display: flex;
  align-items: center;
  outline: none;
  text-indent: 35px;
  width: 100%;
}

.login-person-icon {
  position: absolute;
  font-size: 25px;
  left: 15px;
  color: #6a6a6a;
  user-select: none;
}

.login-lock-icon {
  position: absolute;
  font-size: 25px;
  left: 15px;
  color: #6a6a6a;
  user-select: none;
  /* @media (max-width: $app-breakpoint-s) {
    } */
}

.login-show-password-icon {
  position: absolute;
  font-size: 25px;
  right: 25px;
  color: #6a6a6a;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  /* @media (max-width: $app-breakpoint-s) {
      // right: 75px;
    } */
}

.login-button {
  min-width: 200px;
  background-color: #e84a27;
  border: none;
  color: #ffffff;
  margin: 30px 0;
  font-weight: 600;
}

.login-button:hover {
  background-color: #e94927;
}

.login-error-wrapper {
  display: flex;
  align-items: center;
  color: #f1496b;
  justify-content: center;
  margin-top: 8px;
  /* padding: 0 20px; */
  text-align: center;
}

.login-error-text {
  margin: 0 0 0 5px;
  padding: 0;
}

.login-error-icon {
  font-size: 55px;
}

button.login-button[disabled] {
  /* styles when the element is disabled */
  background-color: #ddd;
  color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}

.login-remember-me-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.login-remember-me {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.login-remember-me-input {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.login-remember-me-text {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 0 8px;
}

.login-forgot-password {
  color: #e84a27;
  font-size: 14px;
  font-weight: 700;
  margin: 0 5px 0 0;
  cursor: pointer;
}

.lds-ring {
  box-sizing: border-box;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
