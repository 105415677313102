.card {
    border-radius: 8px;
    max-width: 400px;
    height: calc(100vh - 64px);
}

/* option-icons */
.family_icon {
    color: var(--blueLight);
    font-size: 20px;
}

.professional_icon {
    color: var(--teal);
    font-size: 20px;
}

.organization_icon {
    color: var(--lime);
    font-size: 20px;
}

.charitable_icon {
    color: var(--gold);
    font-size: 20px;
}

.education_icon {
    color: var(--orange);
    font-size: 20px;
}

.content {
    background: #F2F2F2;
    border: 2px solid #E2E2E2;
    border-top: 0;
    border-radius: 0px 0px 8px 8px;
    height: calc(100% - 249px);
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 16px 0 16px;
}
