.open_region {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.input_region {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
}

.input_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-bottom: 16px;
}

.option {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px 0px 0px 4px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
  text-align: center;
  margin: 0;
  width: 40px;
  border-right: none;
  cursor: pointer;
  max-width: 95px;
  height: 31px;
}

.input {
  border: 2px solid var(--smokeDarker);
  padding: 6px;
  text-align: center;
  outline: none;
  color: #fff;
  width: 95px;
  font-size: 12px;
  border-radius: 0;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.input_edit {
  border: 2px solid var(--smokeDarker);
  padding: 6px;
  text-align: center;
  outline: none;
  min-width: 70px;
  max-width: 150px;
  font-size: 12px;
  border-radius: 0;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  border-radius: 4px;
  height: 31px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

/* slider */
.slider_wrapper {
  padding: 20px 40px;
  position: relative;
}

.min {
  position: absolute;
  left: 0;
  top: 11px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
}

.max {
  position: absolute;
  right: 1px;
  top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
}

.edit_icon {
  height: 31px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
  padding: 0 5px;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  cursor: pointer;
}

.edit_icon:hover {
  color: #ffffff;
}

.edit_close {
  background: #ffffff;
  padding: 4px;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px 0px 0px 4px;
  font-weight: 400;
  font-size: 14px;
  color: var(--charcoal);
  text-align: center;
  margin: 0;
  border-right: none;
  height: 31px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error {
  color: #ff0000;
}
