.wrapper_bar {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

.text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--charcoal) !important;
}

.icon_wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
}
.icon_wrapper_last {
    display: flex;
    align-items: center;
}

.education_icon {
    margin-right: 6px;
}
.check_icon {
    margin-right: 6px;
}
.present_icon {
    margin-right: 6px;
    font-size: 19px;
    margin-bottom: 5px;
}