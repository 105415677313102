.wrapper {

}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--charcoal);
    border-top: 4px solid var(--skyBlue);
}

.card {
    background-color: #fff;
    margin-top: 16px;
    border-radius: 8px;
    border: 2px solid var(--smokeDarker);
    padding: 16px;
}

.label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--greyDarkest);
}

.university_section {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-top: 16px;
}