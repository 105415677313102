.header_wrapper {
  width: 100%;
  height: 50px;
  background: var(--charcoalLight);
  border: 2px solid var(--charcoal);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
  color: #fff;
  font-size: 12px;
}

.header_type {
  border: 1px solid #fff;
  padding: 4px 16px;
  border-radius: 8px;
  width: 82px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.wrapper {
  overflow-y: overlay;
}

.header_row {
  width: 100%;
  height: 20px;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 16px;
  color: #fff;
  font-size: 13px;
}
