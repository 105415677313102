.root {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  position: relative;
  display: flex;
  overflow: hidden;
}

.right_section {
  width: 100%;
  /* transition-duration: 0.15s; */
  background: #f2f2f2;
  padding: 0 1px 0 1px;
  position: relative;
}

.chat_container {
  width: 100px;
}

.full_width {
  width: 100%;
}

.wrapper {
  position: absolute;
  left: 32px;
  top: 32px;
  z-index: 5;
  overflow: overlay;
  height: 740px;
}

.filter_card_wrapper {
  display: flex;
  position: absolute;
  left: 54%;
  transform: translate(-50%, 0);
  z-index: 5;
  top: 16px;
}

@media (max-width: 900px) {
  .filter_card_wrapper {
    display: flex;
    position: absolute;
    left: 58%;
    transform: translate(-50%, 0);
    z-index: 5;
    top: 30px;
  }
}

@media (max-width: 770px) {
  .filter_card_wrapper {
    display: flex;
    position: absolute;
    left: 65%;
    transform: translate(-50%, 0);
    z-index: 5;
    min-width: 350px;
    top: 30px;
  }
}

.section {
  width: 100%;
}

.search_wrapper {
  margin-bottom: 24px;
}

.detailed_wrapper {
  position: absolute;
  top: 50%;
  right: 24px;
  z-index: 10;
  transform: translate(0, -50%);
}

@media (max-width: 900px) {
  .filters {
    display: flex;
    margin-bottom: 12px;
    gap: 2px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.feedback_container {
  display: flex;
  gap: 8px;
  align-items: center;
  bottom: 16px;
  right: 16px;
}

.feedback_button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.feedback_button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}
