.card {
  max-width: 360px;
  box-sizing: border-box;
}

.content {
  height: calc(100% - 32px);
  background-color: #fff;
  width: 100%;
  border: 2px solid #e2e2e2;
  border-top: none;
  border-bottom: none;
  padding: 16px;
  padding-bottom: 0;
}

.content_header {
  display: flex;
  align-items: center;
  width: 100%;
}

.left_section {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.name {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.name_desc {
  font-weight: 700;
  margin: 0 3px 0 0;
  font-size: 24px;
  line-height: 32px;
  color: var(--charcoal);
}

.name_icon {
  font-size: 25px;
  color: #38b2d9;
  margin-top: 3px;
  cursor: pointer;
}

.person_details_wrapper {
  display: flex;
  align-items: center;
}

.person_details_title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--greyDarkest);
  margin-right: 4px;
}

.person_details_value {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--charcoal);
  width: 146px;
  text-overflow: ellipsis;
  overflow-wrap: none;
  overflow: hidden;
  white-space: nowrap;
}

.types {
  display: flex;
  background: var(--smoke);
  border-radius: 4px;
  height: 28px;
  width: 100%;
  margin-top: 16px;
}

.right_section {
  width: 30%;
  height: calc(100% - 28px);
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.giving_prospect_status {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.split_wrapper {
  width: calc(50% - 4px);
}

.affiliation_unit {
  margin-top: 16px;
  margin-bottom: 4px;
}

.button_section {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: var(--smoke);
  border: 2px solid var(--smokeDarker);
  border-radius: 0px 0px 8px 8px;
  border-top: 0;
}

.button_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 6px 0;
  background: var(--skyBlueLight);
  border: 2px solid var(--skyBlue);
  border-radius: 4px;
  min-width: 160px;
}

.button_right_icon {
  color: var(--charcoal);
  font-weight: 600;
  font-size: 20px;
}

.button_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--charcoal);
}

.education_icon {
  color: var(--orange);
  margin-right: 6px;
}
