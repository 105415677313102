.wrapper {
  background: #ffffff;
  border: 2px solid var(--blue);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 16px;
  min-width: 500px;
}

.header_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.text_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header_text {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  color: var(--charcoal);
}

.header_text_clear {
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  margin: 0 4px;
}

.check_circle {
  color: var(--blue);
  margin-left: 6px;
}

.values_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* item */

/* family */
.item_wrapper_undefined {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_undefined:hover {
  border: 2px solid var(--charcoal);
}

.item_wrapper_family {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_family:hover {
  border: 2px solid var(--blue);
}

.item_wrapper_personal {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_personal:hover {
  border: 2px solid var(--teal);
}

.item_wrapper_professional {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_professional:hover {
  border: 2px solid var(--lime);
}

.item_wrapper_charitable {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_charitable:hover {
  border: 2px solid var(--gold);
}

.item_wrapper_education {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_education:hover {
  border: 2px solid var(--orange);
}

.item_wrapper_engagement {
  background: #ffffff;
  border: 2px solid var(--smokeDarker);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.item_wrapper_engagement:hover {
  border: 2px solid var(--magenta);
}

.item_wrapper_selected {
  background: var(--blue);
  border: 2px solid var(--blue);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.item_wrapper_filter_out_selected {
  background: #d9534f;
  border: 2px solid #d9534f;
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.item_wrapper_highlighted {
  background: rgba(9, 96, 228, 0.25);
  border: 2px solid var(--blue);
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 16px;
  color: var(--charcoal);
  cursor: pointer;
}

.nodeFilterHeader {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
}

.nodeFilterHeaderLine {
  margin: 0 10px;
}

.nodeFiltersValuesWrapper {
  display: flex;
  width: 100%;
}

/* --- time filter --- */
.timeFilterRoot {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border-radius: 100px;
  gap: 8px;
}

.inputTimeFilter {
}
