.card {
  width: 100%;
}

.top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--charcoal);
  border: 2px solid var(--charcoal);
  border-radius: 8px 8px 0px 0px;
  padding: 8px 16px;
}

.back_wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow_back {
  color: var(--skyBlue);
}

.back_text {
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.three_dots {
  color: var(--skyBlue);
}

.content {
  background: #3c3c3c;
  border: 2px solid #303030;
  padding: 16px;
  padding-bottom: 0;
}

.connection_wrapper {
  display: flex;
  align-items: center;
}

.connection_type {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 8px;
}

.connection {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

/*--- separate component ---*/
.inputwrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.inputwrapperFull {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  color: var(--smokeDarkest);
}

.input {
  color: #fff;
  background-color: var(--charcoal);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  padding: 2px 0 2px 8px;
  height: 28px;
}

.stengthofrelationshipInput {
  color: #fff;
  background-color: var(--charcoal);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  height: 28px;
}

.strengthOfRelationshipWrapper {
  color: #fff;
  background-color: var(--charcoal);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  padding: 0 5px 0 10px;
  display: flex;
  align-items: center;
  height: 28px;
  cursor: pointer;
}

.arrow_down {
  color: var(--skyBlue);
}

.dropDownCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  gap: 3px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  background-color: #fff;
  border-radius: 0px 0px 8px 8px;
  border: 2px solid #e2e2e2;
  border-top: none;
  top: 50px;
  left: 0;
  width: 100%;
}

.options {
  width: 100%;
  padding: 5px 16px;
}

.options:hover {
  color: #fff;
  background-color: var(--skyBlue);
  font-weight: 700;
}

.is_selected {
  width: 100%;
  text-align: center;
  padding: 5px 0;
  color: #fff;
  background-color: var(--skyBlue);
  font-weight: 700;
}

.strengthOfRelationship {
  display: flex;
  position: relative;
  flex-direction: column;
}

.lds_ring {
  box-sizing: border-box;
  display: block;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #303030 transparent transparent transparent;
}

.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}

@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error {
  color: #f1496b;
  font-size: 12px;
}
