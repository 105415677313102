.updated_date {
  font-size: 12px;
  color: #868686;
  padding-bottom: 5px;
  margin-top: 16px;
  text-align: right;
}

.refresh {
  cursor: pointer;
  color: #007bff;
  margin-left: 10px;
}
