@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}

.placeholder {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  animation: pulse;
  animation-delay: 0.3s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  opacity: 0.1;
}

.dark_placeholder {
  background-color: #000;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  animation: pulse;
  animation-delay: 0.3s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  opacity: 0.1;
}
